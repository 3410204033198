<template>
  <ion-item class="ion-margin-bottom" lines="none">
    <ion-label position="floating">Select Month</ion-label>
    <ion-datetime
      type="text"
      v-model="date"
      :max="new Date().toISOString()"
      display-format="MMM YYYY"
      @ionChange="setDate()"
    ></ion-datetime>
  </ion-item>

  <ion-list
    v-if="resources.data != null && resources.data.length > 0"
    class="ion-margin-top"
  >
    <ion-item v-for="item in days" :key="item.id">
      <ion-label>
        <h2>
          {{ new Date(item.attendance_date).toDateString() }}
        </h2>
        <p v-if="item.updator">
          By: {{ item.updator.user_detail.name }} <br>
          {{ new Date(item.updated_at).toDateString() }}, Class: {{ `${item.section_standard.standard.name} (${item.section_standard.section.name})` }}
        </p>
      </ion-label>
      <ion-note slot="end">
        {{ attendanceState(item.attendance_state_id) }}
      </ion-note>
      <!-- <ion-select
        placeholder="Attendance"
        interface="popover"
        @ionChange="setAttendance(item.user.id, $event.target.value, item)"
        :value="initAttendance(item)"
      >
        <ion-select-option :value="2">Present</ion-select-option>
        <ion-select-option :value="3">Absent</ion-select-option>
      </ion-select> -->
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No records found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonDatetime,
  IonLabel,
  IonItem,
  IonNote,
  // IonSelect,
  // IonSelectOption,
  // alertController,
} from "@ionic/vue";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  emits: ["updateDate"],

  components: {
    IonList,
    IonListHeader,
    IonDatetime,
    IonLabel,
    IonItem,
    IonNote,
    // IonSelect,
    // IonSelectOption,
  },
  data() {
    return {
      date: new Date().toISOString(),

      // xhrError: {
      //   header: null,
      //   description: null,
      // },
    };
  },

  computed: {
    days() {
      const attendances = this.resources.data;
      if (!Array.isArray(attendances) || attendances.length < 1) {
        return attendances;
      }

      const start = moment(this.date).startOf("month");
      const end = moment(this.date).endOf("month").isAfter()
        ? moment()
        : moment(this.date).endOf("month");

      const range = moment.range(start, end);
      let days = [];

      for (let day of range.by("day")) {
        const found = attendances.find((attendance) =>
          moment(attendance.attendance_date).isSame(day)
        );

        const day_to_push = found
          ? found
          : {
              attendance_date: day,
              id: day.format("X"),
              attendance_state_id: 1,
            };

        days.push(day_to_push);
      }

      return days;
    },
  },

  methods: {
    // alerter(header, message, buttons = ["close"]) {
    //   const alert = alertController.create({
    //     header: header,
    //     message: message,
    //     buttons: buttons,
    //   });

    //   return alert;
    // },

    // setErrorResponse(error) {
    //   if (error.response) {
    //     this.xhrError.header = error.response.data.header;
    //     this.xhrError.message = error.response.data.message;
    //   } else if (error.request) {
    //     console.log(error.request);
    //     this.xhrError.header = error.message || error.request;
    //     this.xhrError.message = "Please check your connection and try again";
    //   } else {
    //     this.xhrError.header = error.message;
    //     this.xhrError.message = "Something went wrong. Try again later";
    //   }
    // },

    setDate() {
      this.$emit("updateDate", this.date);
      // console.log(this.date)
    },

    attendanceState(id) {
      let state;

      switch (id) {
        case 1:
          state = "No record";
          break;
        case 2:
          state = "Present";
          break;
        case 3:
          state = "Absent";
          break;
        default:
          state = `Invalid attendance state: ${id}`;
      }

      return state;
    },

    // async setAttendance(user_id, val, item) {
    //   const attendances = item.user.attendances;

    //   const data = {
    //     user_id: user_id,
    //     attendance_state_id: val,
    //     attendance_date: moment(this.date).format("YYYY-MM-DD"),
    //   };

    //   let attendance_id = null;

    //   if (Array.isArray(attendances) && attendances.length > 0) {
    //     attendance_id = attendances[0].id;
    //     await this.updateAttendance(attendance_id, data);
    //   } else {
    //     await this.createAttendance(data);
    //   }

    //   this.$emit("refresh")
    // },

    // async createAttendance(data) {
    //   try {
    //     await this.$store.dispatch("attendance/createResource", data);
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },

    // async updateAttendance(id, data) {
    //   try {
    //     await this.$store.dispatch("attendance/updateResource", { id, data });
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },

    // initAttendance(item) {
    //   const attendances = item.user.attendances;
    //   let attendance = 1;

    //   if (Array.isArray(attendances) && attendances.length > 0) {
    //     attendance = attendances[0].attendance_state_id;
    //   }
    //   return attendance;
    // },
  },
};
</script>